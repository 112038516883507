<template>
  <div class="memberCard">
    <div class="flex_baseline_between">
      <div class="memberCard-title">我的会员卡</div>
      <div class="memberCard-record flex_center">交易记录</div>
    </div>

    <memberCardHead :userInfo="userInfo"></memberCardHead>

    <div class="flex_baseline_between" style="margin-top: 42px">
      <!-- 1 -->
      <div class="memberCard-item">
        <memberCardItem
          v-if="menberType == 1"
          title="基础服务"
          :list="basicServiceList"
        ></memberCardItem>
      </div>

      <!-- 2 -->
      <div class="memberCard-item">
        <memberCardItem
          v-if="menberType == 1"
          title="成员管理"
          :list="manManageList"
          @manWork="handleSkip($event, 'manWork')"
        >
          <template slot="manage">
            <div class="manage">
              <img
                class="manage-img"
                v-for="(item, index) in manageManList"
                :key="index"
                :src="item"
              />
            </div>
          </template>
        </memberCardItem>
      </div>

      <!-- 3 -->
      <div class="memberCard-item">
        <memberCardItem v-if="menberType == 1" title="升级企业版">
          <img class="update" src="@/assets/vip/qiye_icon.png" />
        </memberCardItem>
        <memberCardItem
          v-if="menberType == 2"
          title="会员子账号"
          :list="subAccountList"
        ></memberCardItem>
        <memberCardItem
          v-if="menberType == 3 && userInfo.isVip"
          title="会员权益卡"
        >
          <memberCardOpen></memberCardOpen>
        </memberCardItem>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/user'
import memberCardHead from '../components/memberCardHead'
import memberCardItem from '../components/memberCardItem'
import memberCardOpen from '../components/memberCardOpen'
export default {
  name: 'memberCard',
  components: {
    memberCardHead,
    memberCardItem,
    memberCardOpen
  },
  data() {
    return {
      userInfo: {},
      menberType: 1, // 1-主号, 2-子号, 3-个人
      manageManList: [
        'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
      ],
      basicServiceList: [
        {
          name: '会员专属服务群',
          icon: require(`@/assets/vip/manage_kf.svg`)
        }
      ],
      manManageList: [
        {
          name: '管理成员账号',
          lSlot: 'manage',
          icon: require(`@/assets/vip/manage_cy.svg`)
        },
        {
          name: '成员创作管理',
          emit: 'manWork',
          icon: require(`@/assets/vip/manage_cz.svg`)
        }
      ],
      subAccountList: [
        {
          name: '退出团队子账号',
          icon: require(`@/assets/vip/manage_tc.svg`)
        },
        {
          name: '创作管理',
          icon: require(`@/assets/vip/manage_cz.svg`)
        }
      ]
    }
  },
  mounted() {
    this.userInfo = getUserInfo()
    console.log('userInfo', this.userInfo)
  },
  methods: {
    /* 跳转 */
    handleSkip(item, pathname) {
      let path = null
      switch (pathname) {
        case 'manWork':
          path = '/my/memberProduct'
          break
      }
      if (!path) return
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.memberCard {
  width: 1402px;
  margin: 0 auto;
  &-title {
    font-size: 40px;
    font-weight: bold;
    color: #3d3d3d;
  }
  &-record {
    width: 119px;
    height: 34px;
    border-radius: 17px;
    border: 1px solid #000000;
    font-size: 16px;
    color: #3d3d3d;
    cursor: pointer;
  }
  &-item {
    width: 445px;
    flex-shrink: 0;
    .manage {
      margin-left: 10px;
      display: flex;
    }
    .manage-img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin-right: 2px;
      flex-shrink: 0;
    }
    .update {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_col_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex_baseline_between {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>