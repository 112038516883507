<template>
  <div>
    <el-checkbox-group v-model="checkList" @change="handleCheckbox">
      <div class="albumInfoList" v-if="albumList.length" v-loading="loading">
        <div
          class="albumInfoList-item"
          v-for="(item, index) in albumList"
          :key="index"
        >
          <albumInfoItem
            :isSelected="isSelected"
            :imgUrl="item.imgUrl"
            :issue="item.issue"
            :checkValue="item.id"
            :showMask="checkList.includes(item.id)"
          ></albumInfoItem>
        </div>
      </div>
      <el-empty v-else :image-size="200"></el-empty>
    </el-checkbox-group>

    <comfirmDialog
      v-model="delDialogVisible"
      title="移出作品"
      content="确定要移出选中的作品吗？"
      cancelTxt="取消"
      @confirm="handleConfirmRemove"
    ></comfirmDialog>
  </div>
</template>

<script>
import albumInfoItem from './albumInfoItem.vue'
import comfirmDialog from '@/components/comfirmDialog/index'
import { userCollectionImgList, batchRemoveImg } from '@/api/album/index.js'
export default {
  name: 'albumInfoList',
  components: { albumInfoItem, comfirmDialog },
  props: {
    collectionId: {
      type: String,
      default: null
    },
    collectionName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      isSelected: false,
      delDialogVisible: false,
      albumList: [],
      checkList: []
    }
  },
  watch: {
    collectionId: {
      deep: true,
      immediate: true,
      handler: 'handleInit'
    }
  },
  methods: {
    /* 初始化 */
    handleInit() {
      if (!this.collectionId) return
      this.loading = true
      userCollectionImgList({
        page: 1,
        limit: -1,
        collectionArticleId: this.collectionId
      })
        .then((res) => {
          this.albumList = (res && res.rows) || []
          console.log('初始化', this.albumList)
        })
        .finally(() => {
          this.loading = false
        })
    },

    /* 是否选择 */
    handleSelect(status) {
      this.checkList = []
      this.isSelected = status
    },

    /* 多选 */
    handleCheckbox(e) {
      console.log('handleCheckbox', e, this.checkList)
    },

    /* 移除作品 */
    handleRemove() {
      if (!this.checkList.length) {
        return this.$message.error('请至少选择一个作品')
      }
      this.delDialogVisible = true
    },
    handleConfirmRemove() {
      batchRemoveImg(this.collectionId, this.checkList).then((res) => {
        if (res.code != 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.delDialogVisible = false
        this.checkList = []
        this.handleInit()
      })
    },

    /* 下载作品 */
    handleDownload() {
      if (!this.checkList.length) {
        return this.$message.error('请至少选择一个作品')
      }
      this.checkList.map((v, i) => {
        let item = this.albumList.find((d) => d.id == v)
        if (item) {
          this.downLoadFile(item.imgUrl)
        }
      })
      this.checkList = []
    },
    async downLoadFile(imageUrl) {
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${this.collectionName}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.albumInfoList {
  width: 100%;
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
  &-item {
    width: 220px;
    margin-right: 14px;
  }
  &-item:nth-child(6n) {
    margin-right: 0;
  }
}
</style>