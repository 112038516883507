<template>
  <div>
    <!-- tab -->
    <div class="flex items-center justify-center relative mt-[18px]">
      <div class="flex justify-center">
        <div
          class="ml-6 cursor-pointer text-[#3D3D3D]"
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ active_index: index === tabIndex }"
          @click="tabIndex = index"
        >
          <div class="text-base" :class="{ sort_custom: item.sortType }">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div v-if="tabIndex == 0" class="flex justify-center absolute right-0">
        <div
          class="ml-6 cursor-pointer text-[#3D3D3D]"
          v-for="(item, index) in sortList"
          :key="index"
          :class="{ active_index: index === sortIndex }"
          @click="sortIndex = index"
        >
          <div class="text-base" :class="{ sort_custom: item.sortType }">
            {{ item.name }}
            <template v-if="item.sortType">
              <div class="triangle-top"></div>
              <div class="triangle-bottom"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- list -->
    <div
      v-if="articleList.length && [0, 1].includes(tabIndex)"
      class="grid grid-cols-6 mt-[29px]"
    >
      <div
        class="mt-2 rounded-xl cursor-pointer group"
        v-for="(item, index) in articleList"
        :key="index"
        @click="$router.push(`/service/detail/${item.id}`)"
      >
        <PreviewCard
          v-if="tabIndex == 0"
          :sourceId="item.id"
          :cover="item.coverImgUrl"
          :title="item.title"
          :price="9.9"
          :service="10"
        />
        <PreviewCard
          v-else
          :isMask="false"
          :isInfo="false"
          :isLike="false"
          :tuceNum="0"
          :title="item.title"
          :sourceId="item.id"
          :cover="item.coverImgUrl"
        />
      </div>
    </div>
    <!-- nodata -->
    <noData v-else-if="!articleList.length"></noData>
    <!-- about -->
    <div class="w-full text-left pb-[100px] relative">
      <div class="text-lg font-bold mt-[36px]">创作领域</div>
      <div class="flex">
        <div class="about_span">潮鞋设计</div>
        <div class="about_span">品牌文化创意</div>
        <div class="about_span">厨窗陈列</div>
        <div class="about_span">插画设计</div>
      </div>
      <div class="text-lg font-bold mt-[36px]">行家简介</div>
      <div class="about_desc">
        提供专业的鞋类设计服务作品可提供文件格式为PSD/AI/CDR/PDF提供专业的鞋类设计服务作品可提供文件格式为PSD/AI/CDR/PDF提供专业的鞋类设计服务作品可提供文件格式为PSD/AI/CDR/PDF
      </div>
      <div class="text-lg font-bold mt-[36px]">位置</div>
      <div class="flex items-center mt-[16px]">
        <img src="@/assets/position.svg" />
        <div class="about_desc" style="margin-top: 0; margin-left: 7px">
          中国.福建省.泉州市
        </div>
      </div>
      <div class="text-lg font-bold mt-[36px]">关联成员</div>
      <div class="flex items-center mt-[16px]">
        <img
          v-for="(item, index) in 3"
          :key="index"
          class="w-[35px] h-[35px] rounded-full mr-[12px]"
          src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
        />
      </div>
      <div class="about_tips">自2024年6月8日注册服务商</div>
    </div>
  </div>
</template>

<script>
import { findMiniList } from '@/api/home'
import PreviewCard from '@/components/previewCard'
import noData from '@/components/noData/index.vue'
export default {
  name: 'homeTabService',
  components: { PreviewCard, noData },
  data() {
    return {
      pageNum: 1,
      tabIndex: 0,
      sortIndex: 0,
      articleList: [],
      tabList: [{ title: '服务项目' }, { title: '图册' }, { title: '关于' }],
      sortList: [{ name: '最新' }, { name: '热门', sortType: 1 }]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // !!!todo: 接口联调
    async getList(change) {
      const result = await findMiniList({
        page: this.pageNum
      })
      if (!change) {
        this.articleList = result.rows
      } else {
        this.articleList.push(...result?.rows)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sort_custom {
  @apply relative;
  padding-right: 12px;
  .triangle-top,
  .triangle-bottom {
    @apply absolute right-0;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
  .triangle-top {
    @apply top-1;
    border-bottom: 6px solid #a09e9f;
  }
  .triangle-bottom {
    @apply bottom-1;
    border-top: 6px solid #a09e9f;
  }
}
.active_index {
  @apply font-black;
  color: #000;
}
.about_span {
  padding: 1px 12px;
  box-sizing: border-box;
  background: #f4f5f7;
  border-radius: 14px;
  font-size: 16px;
  color: #000000;
  margin: 13px 0;
  margin-right: 17px;
}
.about_desc {
  font-size: 16px;
  color: #666666;
  margin-top: 13px;
}
.about_tips {
  left: 50%;
  bottom: 53px;
  position: absolute;
  transform: translateX(-50%);
  font-size: 16px;
  color: #c1c1c1;
}
</style>