<template>
  <div class="memberProduct">
    <div class="_flex">
      <div class="memberProduct-title">成员作品</div>
      <div class="flex">
        <div
          class="memberProduct-tab"
          v-for="(item, index) in memberList"
          :key="index"
          :class="{ active_router: item.key === currentKey }"
          @click="handleInit(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'memberProduct',
  data() {
    return {
      currentKey: 1,
      memberList: [
        { name: '张小明', key: 1 },
        { name: '王二狗', key: 2 },
        { name: '我是一个大老黑', key: 3 }
      ]
    }
  },
  methods: {
    handleInit(item) {
      this.currentKey = item.key
    }
  }
}
</script>

<style lang="scss" scoped>
.memberProduct {
  width: 1402px;
  margin: 0 auto;
  &-title {
    font-size: 40px;
    font-weight: bold;
    color: #3d3d3d;
    margin-right: 66px;
  }
  &-tab {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    margin-right: 46px;
    cursor: pointer;
  }
}
.active_router {
  @apply font-black relative;
  color: #000;
  &::after {
    @apply block absolute right-0.5 bottom-0.5 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
._flex {
  display: flex;
  align-items: center;
}
</style>