<template>
  <div class="p-4" v-loading="loading">
    <div class="flex justify-between items-center">
      <div class="text-3xl">开通（鞋样设计）专业版会员</div>
      <div
        class="w-20 h-8 leading-7 border border-black rounded-2xl cursor-pointer"
        @click="showList = true"
      >
        记录
      </div>
    </div>
    <div class="w-full mt-4 h-[660px] rounded-2xl bg-black p-4">
      <div class="bg-[rgba(77,77,77,.5)] w-full text-[#C7B480] h-[350px] p-4">
        <div class="text-left">专业版会员权益</div>
        <div class="flex mt-2">
          <div class="w-[207px] text-left">权益类型</div>
          <div
            class="w-[112px] text-lg font-bold cursor-pointer"
            :class="activeKey.name === 'baiyin' && 'baiyin'"
            @click="changeKey(0)"
          >
            白银权益卡
          </div>
          <div
            class="w-[112px] text-lg font-bold cursor-pointer"
            :class="activeKey.name === 'huangjin' && 'huangjin'"
            @click="changeKey(1)"
          >
            黄金权益卡
          </div>
          <div
            class="w-[112px] text-lg font-bold cursor-pointer"
            :class="activeKey.name === 'bojin' && 'bojin'"
            @click="changeKey(2)"
          >
            铂金权益卡
          </div>
        </div>
        <div class="h-[270px] overflow-y-auto scrollbar">
          <table>
            <tbody>
              <tr>
                <td>使用时长</td>
                <td>6个月</td>
                <td>12个月</td>
                <td>24个月</td>
              </tr>
              <tr>
                <td>生成次数</td>
                <td>无限</td>
                <td>无限</td>
                <td>无限</td>
              </tr>
              <tr>
                <td>加速卡</td>
                <td>300次/月</td>
                <td>500次/月</td>
                <td>600次/月</td>
              </tr>
              <tr>
                <td>登录IP数</td>
                <td>包含1个</td>
                <td>包含2个</td>
                <td>包含4个</td>
              </tr>
              <tr>
                <td>云空间</td>
                <td>1GB</td>
                <td>10GB</td>
                <td>无限</td>
              </tr>
              <tr>
                <td>条件生图</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
              <tr>
                <td>图片融合</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
              <tr>
                <td>自由创作</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
              <tr>
                <td>局部修改</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
              <tr>
                <td>配件/材料资源调用</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
              <tr>
                <td>配色/配料</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
              <tr>
                <td>设计资讯</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
              <tr>
                <td>一键同款</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
              <tr>
                <td>团队协同</td>
                <td>✔</td>
                <td>✔</td>
                <td>✔</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-2 text-white h-[200px] ml-16">
        <div>
          <div class="flex items-center text-[#C7B480]">
            <div>增加登录IP：</div>
            <el-input-number
              v-model="addIp"
              :min="0"
              :max="99"
              @change="getVipCode"
            ></el-input-number>
            <span class="text-2xl ml-1">个</span>
            <div class="ml-24 text-[#FF612E] text-lg">{{ addIp * 3000 }}元</div>
          </div>
          <div class="mt-2 text-left font-thin text-sm text-[#C7B480]">
            此套餐默认“1个”登录IP，增加登录IP需支付<span class="text-[#FF612E]"
              >￥3000/个</span
            >
          </div>
          <div class="mt-2 flex items-center text-[#C7B480]">
            <div class="text-lg">总登录IP数:</div>
            <div class="text-[#FF612E] text-lg font-bold ml-2">
              {{ addIp + 1 }}个
            </div>
          </div>
        </div>
        <div class="flex justify-between mt-2 items-center">
          <div>
            <div
              id="vipQrCode"
              ref="vipQrCode"
              class="w-[160px] h-[160px]"
            ></div>
          </div>
          <div>
            <div class="text-left">
              应付：
              <span class="text-[#ff7744] text-3xl"
                >￥{{
                  Number(
                    vipList.find((item) => item.name === activeKey.name).price
                  ) +
                  addIp * 3000
                }}元</span
              >
            </div>
            <div class="mt-4 flex justify-between items-center">
              <div class="text-sm">使用微信/支付宝扫码支付</div>
              <div
                class="text-[#9b9b9b] border border-[#9b9b9b] text-sm ml-4 px-1 cursor-pointer"
              >
                发票开具入口
              </div>
            </div>
            <div class="my-4 text-left text-sm cursor-pointer">刷新二维码</div>
            <div class="flex justify-start items-center">
              <el-checkbox v-model="agree"
                >购买即视为同意《会员服务协议》</el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <vipList v-model="showList" />
  </div>
</template>
  
  <script>
import { createMemberQR, getMemberDetails } from '@/api/vip'
import vipList from './vipList.vue'
import QRCode from 'qrcodejs2'

export default {
  name: 'buyvip',
  data() {
    return {
      vipList: [
        {
          key: '1',
          name: 'baiyin',
          price: '10800'
        },
        {
          key: '2',
          name: 'huangjin',
          price: '21600'
        },
        {
          key: '3',
          name: 'bojin',
          price: '43200'
        }
      ],
      activeKey: {
        key: '1',
        name: 'baiyin',
        price: '10800'
      },
      agree: false,
      showList: false,
      addIp: 0,
      qrcode: '',
      loading: false,
      interval: null
    }
  },
  components: {
    vipList
  },
  methods: {
    changeKey(key) {
      this.activeKey = this.vipList[key]
      this.getVipCode()
    },
    async getVipCode() {
      this.loading = true
      const result = await createMemberQR({
        userVipId: this.activeKey?.key,
        ipAddCount: this.addIp
      })
      if (result) {
        this.qrcode = result.data.content
        this.generatorQrCode()
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          this.queryPayInfo(result.data.orderId)
        }, 1500)
      }
      this.loading = false
    },
    generatorQrCode() {
      if (this.$refs.vipQrCode) {
        this.$refs.vipQrCode.innerHTML = ''
      }
      new QRCode('vipQrCode', {
        text: this.qrcode,
        width: 160,
        height: 160,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 轮询查询支付状态
    async queryPayInfo(id) {
      const result = await getMemberDetails({ id })
      if (result.data.payStatus === 1) {
        clearInterval(this.interval)
        this.$message.success('支付成功')
        this.$emit('pay')
      }
    }
  },
  mounted() {
    this.getVipCode()
  },
  destroyed() {
    clearInterval(this.interval)
  }
}
</script>
  
<style lang="scss" scoped>
table {
  width: 100%;
  margin-top: 16px;
  border-collapse: collapse;
  border: 1px solid #c7b480;
  td {
    padding: 6px;
    border: 1px solid #c7b480;
    text-align: left;
  }
}
.scrollbar::-webkit-scrollbar {
  width: 4px; /*  设置纵轴（y轴）轴滚动条 */
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #727272;
}
.scrollbar::-webkit-scrollbar-track {
  background: #000;
}

::v-deep .el-input-number {
  display: flex;
  align-items: center;
  &__increase,
  &__decrease {
    background-color: black;
    color: white;
    border: 1px solid #c7b480;
    height: 30px; /* 减少按钮高度 */
    line-height: 30px;
    color: #c7b480;
    width: 30px;
    font-weight: bolder;
    top: unset !important;
  }
  input {
    width: 80px !important;
    padding-left: 10px;
    padding-right: 10px;
    background-color: black;
    color: #c7b480;
    border: 1px solid #c7b480;
    height: 30px; /* 减少输入框高度 */
  }
}
.baiyin {
  background: radial-gradient(rgba(237, 240, 241, 1), rgba(201, 217, 222, 1));
  color: #7b91a5;
  border-radius: 6px;
}
.huangjin {
  background: radial-gradient(rgba(255, 236, 193, 1), rgba(234, 167, 113, 1));
  color: #c08049;
  border-radius: 6px;
}
.bojin {
  background: radial-gradient(rgba(247, 244, 255, 1), rgba(171, 148, 254, 1));
  color: #885dd6;
  border-radius: 6px;
}
</style>