<template>
  <div class="recommend-list">
    <div
      class="recommend-item cursor-pointer"
      v-for="(item, index) in list"
      :key="index"
      @click="$router.push(`/service/detail/${item.id}`)"
    >
      <PreviewCard
        :sourceId="item.id"
        :cover="item.coverImgUrl"
        :title="item.title"
        :price="9.9"
        :service="10"
      />
    </div>
  </div>
</template>

<script>
import { findMiniList } from '@/api/home'
import PreviewCard from '@/components/previewCard'

export default {
  name: 'recommendList',
  components: {
    PreviewCard
  },
  data() {
    return {
      list: []
    }
  },
  methods: {
    // 根据当前类型获取列表数据
    async getList() {
      const result = await findMiniList({
        page: 1
      })
      const rows = result.rows || []
      this.list = rows.slice(0, 4)
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.recommend-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 40px;
  .recommend-item {
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>