<template>
  <div>
    <div v-if="noteList.length" class="grid grid-cols-5">
      <div
        class="w-[333px] mt-2 cursor-pointer mb-[58px]"
        v-for="(item, index) in noteList"
        :key="index"
      >
        <!-- 图片集 -->
        <div v-if="item.imgList.length" class="rounded-xl relative">
          <img class="w-[333px] h-[232px] rounded-xl" :src="item.imgList[0]" />
          <div class="imgNum">{{ item.imgList.length }}图</div>
        </div>

        <!-- 信息 -->
        <template>
          <div v-if="item.desc && item.desc != ''" class="infoDesc">
            {{ item.desc }}
            <div
              class="text-base font-[300] text-[#666666] absolute right-0 bottom-0"
            >
              {{ item.time }}
            </div>
          </div>
          <div class="mt-[18px] flex items-center justify-end">
            <img v-if="item.isAttend" src="@/assets/attendFull.svg" />
            <img v-else src="@/assets/attend.svg" />
            <div class="text-sm font-[300] text-[#666666] ml-[5px] mr-[17px]">
              {{ item.attendNum || 0 }}
            </div>
            <img v-if="item.isZan" src="@/assets/zanFull.svg" />
            <img v-else src="@/assets/zan.svg" />
            <div class="text-sm font-[300] text-[#666666] ml-[5px] mr-[17px]">
              {{ item.zanNum || 0 }}
            </div>
            <img v-if="item.isComment" src="@/assets/commentFull.svg" />
            <img v-else src="@/assets/comment.svg" />
            <div class="text-sm font-[300] text-[#666666] ml-[5px]">
              {{ item.commentNum || 0 }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <noData v-else></noData>
  </div>
</template>

<script>
import noData from '@/components/noData/index.vue'
export default {
  name: 'homeTabNote',
  components: { noData },
  data() {
    return {
      noteList: []
    }
  },
  mounted() {
    // this.getList()
  },
  methods: {
    getList() {
      let noteList = [
        {
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: true,
          isAttend: false,
          desc: null,
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg'
          ]
        },
        {
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: true,
          desc: '这里是合集简介，如果没有就为空。',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          isComment: true,
          desc: '我不是AI调教师。我不会生成这种指令，也不会生成这种图像。我觉得这个请求不合适，也可能有版权问最多显示3行，超出请用3点来表示表示还是到货时间和大家好',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg'
          ]
        },
        {
          title: '22',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '33',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '44',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '55',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '66',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '77',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '88',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        }
      ]
      noteList = noteList.map((v) => {
        let number = (v.desc && v.desc.length) || 0
        if (number > 50) {
          v.desc = v.desc.slice(0, 50)
          v.desc = v.desc + '...'
        }
        return v
      })
      this.noteList = noteList
    }
  }
}
</script>

<style lang="scss" scoped>
.imgNum {
  padding: 2px 10px;
  box-sizing: border-box;
  width: max-content;
  border-radius: 14px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 11px;
  top: 11px;
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  color: #ffffff;
}
.infoDesc {
  width: 333px;
  font-size: 15px;
  color: #3d3d3d;
  text-align: left;
  margin-top: 10px;
  font-weight: 500;
  position: relative;
}
.infoTips {
  width: 36px;
  height: 17px;
  border-radius: 5px;
  text-align: center;
  line-height: 17px;
  font-size: 10px;
  color: #ffffff;
  margin-right: 14px;
}
</style>