<template>
  <div v-if="value">
    <div
      class="z-[999] fixed top-0 left-0 w-full h-screen overflow-hidden bg-[rgba(0,0,0,.7)] flex justify-center items-center"
    >
      <div
        class="relative rounded-[14px] py-4 comfirmDialog"
        :style="{ height, width, background }"
      >
        <div
          class="text-2xl font-bold flex items-center"
          :style="{
            width: '100%',
            padding: '0 25px',
            boxSizing: 'box-sizing',
            justifyContent: titleAlign == 'left' ? 'flex-start' : titleAlign
          }"
        >
          <div>{{ title }}</div>
          <slot name="titleRight"></slot>
        </div>
        <div
          class="absolute cursor-pointer w-[44px] h-[44px] top-[12px] right-[15px] rounded-[10px] border border-[#E1E8E8] flex items-center justify-center"
          @click="$emit('input', false)"
        >
          <i class="el-icon-close" style="font-size: 24px; color: #666666"></i>
        </div>
        <slot>
          <div class="text-[#666666] text-lg" style="padding: 0 25px">
            {{ content }}
          </div>
        </slot>
        <div
          class="flex"
          style="width: 100%; padding: 0 25px; box-sizing: box-sizing"
        >
          <div class="button" @click="$emit('input', false)" v-if="showCancel">
            {{ cancelTxt }}
          </div>
          <div class="button active" @click="handleConfirm">
            {{ confirmTxt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
export default {
  name: 'comfirmDialog',
  props: {
    // v-model: 是否显示对话框
    value: {
      type: Boolean,
      default: false
    },
    // 宽度
    width: {
      type: String,
      default: '389px'
    },
    // 高度
    height: {
      type: String,
      default: '263px'
    },
    // 背景色
    background: {
      type: String,
      default: '#fff'
    },
    // 标题
    title: {
      type: String,
      default: '操作提示'
    },
    // 内容
    content: {
      type: String,
      default: '确认取消操作?'
    },
    // 取消按钮文本
    cancelTxt: {
      type: String,
      default: '我再想想'
    },
    // 确定按钮文本
    confirmTxt: {
      type: String,
      default: '确认'
    },
    // 是否展示取消按钮
    showCancel: {
      type: Boolean,
      default: true
    },
    // 标题位置
    titleAlign: {
      type: String,
      default: 'center'
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>
  
<style lang="scss" scoped>
.comfirmDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 33px !important;
}
.button {
  flex: 1;
  //   width: 165px;
  height: 54px;
  border-radius: 27px;
  background: #b0b0b0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
.active {
  background: #54c752;
}
.button + .button {
  margin-left: 17px;
}
</style>