<template>
  <div>
    <sortHeader :searchKeys="searchKeys" :defaultSearchKey="defaultSearchKey"
                @changeSearchKey="changeSearchKey"></sortHeader>
    <div class="grid grid-cols-5">
      <div v-if="params.articleType == 2" v-for="(item,index) in dataList" :key="index"
           class="w-80 h-96 border-b flex flex-col items-center justify-between">
        <div class="cursor-pointer"
             @click="defaultSearchKey == 'book' ? $router.push(`/discover/detail/${item.id}`) : ''">
          <div class="w-80 h-56 bg-[#F4F5F7] rounded-lg grid grid-cols-3 gap-2 p-1 ">
            <img v-for="(itemImg,itemIndex) in item.rowImgs" :key="itemIndex" :src="itemImg.imgUrl" alt=""
                 class="w-24 h-24 rounded-lg">
          </div>
          <div class="flex justify-between items-center mt-2">
            <div class="overflow-hidden whitespace-nowrap text-ellipsis w-44 font-bold text-lg"
                 style="text-align: left">
              {{ item.title || "" }}
            </div>
            <div class="font-light text-base">{{ item.createTime.split(" ")[0] }}</div>
          </div>
        </div>
        <div class="flex justify-between items-center p-1 w-80">
          <div class="flex items-center">
            <img :src="item.creatorUser.avatar" alt="" class="w-5 h-5 rounded-full">
            <span class="ml-2 font-normal">{{ item.creatorUser.nickName }}</span>
          </div>
          <div class="flex">
            <div class="flex items-center cursor-pointer">
              <img src="@/assets/star.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">{{ item.attenCount || 0 }}</span>
            </div>
            <div class="flex items-center cursor-pointer ml-4">
              <img src="@/assets/love.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">{{ item.likeCount || 0 }}</span>
            </div>
            <div class="flex items-center cursor-pointer ml-4">
              <img src="@/assets/discuss.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">{{ item.commentCount || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="params.articleType == 3" v-for="(item,index) in dataList" :key="index"
           class="w-80 h-96 border-b flex flex-col items-center justify-between">
        <div class="cursor-pointer"
             @click="$router.push(`/discover/pageDetail/${item.id}`)">
          <div class="w-80 h-56 rounded-lg grid">
            <div style="width: 333px;height: 232px;overflow: hidden;border-radius: 20px">
              <img :src="item.coverImgUrl" alt="" style="width: 100%;height: 100%">
            </div>
          </div>
          <div class="flex justify-between items-center mt-2">
            <div class="overflow-hidden whitespace-nowrap text-ellipsis w-44 font-bold text-lg"
                 style="text-align: left;color: #3D3D3D">
              {{ item.title || "" }}
            </div>
            <div class="font-light text-base">{{ item.createTime.split(" ")[0] }}</div>
          </div>
        </div>
        <div class="flex justify-between items-center p-1 w-80">
          <div class="flex items-center">
            <img :src="item.creatorUser.avatar" alt="" class="w-5 h-5 rounded-full">
            <span class="ml-2 font-normal">{{ item.creatorUser.nickName }}</span>
          </div>
          <div class="flex">
            <div class="flex items-center cursor-pointer">
              <img src="@/assets/star.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">{{ item.attenCount || 0 }}</span>
            </div>
            <div class="flex items-center cursor-pointer ml-4">
              <img src="@/assets/love.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">{{ item.likeCount || 0 }}</span>
            </div>
            <div class="flex items-center cursor-pointer ml-4">
              <img src="@/assets/discuss.svg" alt="" class="w-4 h-4">
              <span class="ml-1 text-sm">{{ item.commentCount || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userCollectionImgList } from "@/api/album/index.js";
import { findMiniList } from "@/api/home";
import sortHeader from "@/components/sortHeader";

export default {
  components: { sortHeader },
  name: "discover",
  data() {
    return {
      searchKeys: [{
        name: "合集",
        id: "book"
      }, {
        name: "笔记",
        id: "page"
      }],
      defaultSearchKey: "book",
      params: {
        page: 1,
        limit: 10,
        articleType: 2
      },
      dataList: [],
      total: 0
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    changeSearchKey(data) {
      this.defaultSearchKey = data;
      this.getDataList();
    },
    getDataList() {
      if (this.defaultSearchKey == "book") {
        this.params.articleType = 2;
      } else if (this.defaultSearchKey == "page") {
        this.params.articleType = 3;
      }
      findMiniList(this.params).then(res => {
        if (res.code == 0) {
          this.dataList = res.rows ? res.rows : [];
          this.total = res.total;
          if (this.dataList && this.dataList.length > 0) {
            if (this.params.articleType == 2) {
              for (let i = 0; i < this.dataList.length; i++) {
                let item = this.dataList[i];
                userCollectionImgList({ collectionArticleId: item.id, page: 1, limit: 6 }).then(itemRes => {
                  if (itemRes.code == 0) {
                    const rowImgs = itemRes.rows ? itemRes.rows : [];
                    this.$set(this.dataList[i], "rowImgs", rowImgs);
                  }
                });
                console.log("this.dataList===", this.dataList);
              }
            }
          }
        }
      });
    }
  }
};
</script>

<style>

</style>
