<template>
  <div class="grid views">
    <div
      class="mt-2 rounded-xl cursor-pointer group"
      v-for="(item, index) in articleList"
      :key="index"
    >
      <PreviewCard
        :isMask="false"
        :isInfo="false"
        :sourceId="item.id"
        :cover="item.coverImgUrl"
        :title="item.title"
        :price="9.9"
        :service="10"
      />
    </div>
  </div>
</template>

<script>
import { findMiniList } from '@/api/home'
import PreviewCard from '@/components/previewCard'
export default {
  name: 'homeTabAi',
  components: { PreviewCard },
  data() {
    return {
      pageNum: 1,
      articleList: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // !!!todo:接口联调
    async getList(change = false) {
      const result = await findMiniList({
        page: this.pageNum
      })
      if (!change) {
        this.articleList = result.rows
      } else {
        this.articleList.push(...result?.rows)
      }
    }
  }
}
</script>

<style>
.views {
  grid-template-columns: repeat(6, 295px);
}
</style>