import Textgeneration from "@/views/workbench/pages/TextGenerator.vue"
import Imagegeneration from "@/views/workbench/pages/ImageGenerator.vue"
import ImageAnalysis from "@/views/workbench/pages/ImageAnalysis.vue"
import Regenerator from "@/views/workbench/pages/regenerator.vue"
import ConditionGeneration from "@/views/workbench/pages/ConditionGeneration.vue"
import Creative from "@/views/workbench/pages/Creative.vue"
import Fusion from "@/views/workbench/pages/Fusion.vue"
import Part from "@/views/workbench/pages/part.vue"
import Color from "@/views/workbench/pages/color.vue"

export default [{
    path: "textgeneration",
    name: "textgeneration",
    component: Textgeneration,
    meta: {
      title: '工作台-文生图'
    }
  },
  {
    path: "imagegeneration",
    name: "imagegeneration",
    component: Imagegeneration,
    meta: {
      title: '工作台-图生图'
    }
  },
  {
    path: "imageanalysis",
    name: "imageanalysis",
    component: ImageAnalysis,
    meta: {
      title: '工作台-图片解析'
    }
    },
    {
        path: "conditiongeneration",
        name: "conditiongeneration",
        component: ConditionGeneration,
        meta: {
          title: "工作台-条件生成" 
        }
    },
    {
        path: "fusion",
        name: "fusion",
        component: Fusion,
        meta: {
          title: "工作台-图片融合"
        }
    },
    {
        path: "creative",
        name: "creative",
        component: Creative,
        meta: {
          title: "工作台-自主创作"
        }
    },
  {
    path: "regenerator/:id/:index/:mode",
    name: "regenerator",
    component: Regenerator,
    meta: {
      title: '工作台-重新生成'
    }
    },
    {
        path: "part/:id/:itemid/:index",
        //path: "part",
        name: "part",
        component: Part,
        meta: {
            title: '工作台-配件选择'
        }
    },
    {
        path: "color/:id/:itemid/:index",
        //path: "part",
        name: "color",
        component: Color,
        meta: {
            title: '工作台-配色配料'
        }
    },
]