<template>
  <div class="offer-price">
    <div class="user-info">
      <div class="avatar">
        <img :src="priceData.serverUser && priceData.serverUser.avatar" />
      </div>
      <div class="user-name">{{ priceData.serverUser.nickName }}</div>
      <div class="attention">+关注</div>
    </div>
    <div class="offer-info">
      <div class="offer-title">{{ priceData.serverTitle }}</div>
      <div class="price-content">
        <div class="base">￥{{ priceData.serverPrice }}元/款</div>
        <div class="vip">会员价：{{ priceData.serverPriceVip }}元/款</div>
        <div class="service">服务{{ priceData.serverCount }}次</div>
      </div>
      <div class="tips">
        <div class="auth-icon">
          <img class="icon-bg" src="@/assets/service/star_bg.svg" />
          <img class="icon-main" src="@/assets/service/auth_icon.svg" />
        </div>
        <div class="tip-text">资金由平台监管，确认服务完成后结算</div>
      </div>
    </div>
    <div class="offer-form">
      <div class="form-title">项目介绍</div>
      <div class="form-content">
        <div class="form-item" v-for="(item, index) in formItems" :key="index">
          <div class="form-item-label">{{ item.label }}</div>
          <div class="form-item-value">{{ priceData[item.prop] }}</div>
        </div>
      </div>
      <div class="form-title">其他说明</div>
      <div class="form-content">
        <div class="form-item">
          <div class="form-item-label">不包含/客户自费</div>
          <div class="form-item-value">{{ priceData.serverOtherRemark }}</div>
        </div>
      </div>
    </div>
    <div class="count-info">
      <div class="count-label">购买数量：</div>
      <div class="num-input">
        <div class="input-button" @click="handleNum(-1)">
          <img src="@/assets/service/dec_icon.svg" />
        </div>
        <div class="input-value">{{ numVal }}</div>
        <div class="input-button" @click="handleNum(1)">
          <img src="@/assets/service/add_border.png" />
          <img src="@/assets/service/add_icon.png" />
        </div>
        <div class="unit">款</div>
      </div>
      <div class="pay-info">
        实付￥
        <span class="money">{{ payMoney }}元</span>
      </div>
    </div>
    <div class="action-content">
      <div class="action-list">
        <div
          class="action-item"
          v-for="(item, index) in actionList"
          :key="index"
        >
          <img class="action-icon" :src="item.icon" />
          <div class="action-name">{{ item.name }}</div>
        </div>
      </div>
      <div class="pay-button" @click="handlePay">
        立即支付￥{{ payMoney }}元
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/user'
import ServiceIcon from '@/assets/service/service_icon.svg'
import MoreIcon from '@/assets/service/more_icon.svg'
import ShareIcon from '@/assets/service/share_icon.svg'

export default {
  name: 'offerPrice',
  props: {
    priceData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formItems: [
        {
          label: '服务内容',
          prop: 'serverContent'
        },
        {
          label: '包含内容',
          prop: 'serverIncludeContent'
        },
        {
          label: '项目数量',
          prop: 'serverProjectCount'
        },
        {
          label: '交付标准',
          prop: 'serverDeliveryStandard'
        },
        {
          label: '交付时间',
          prop: 'serverDeliveryTime'
        },
        {
          label: '支持修改',
          prop: 'supportEdit'
        }
      ],
      numVal: 1,
      actionList: [
        {
          name: '客服',
          icon: ServiceIcon
        },
        {
          name: '更多',
          icon: MoreIcon
        },
        {
          name: '分享',
          icon: ShareIcon
        }
      ]
    }
  },
  computed: {
    payMoney() {
      let info = getUserInfo()
      return (
        this.numVal *
        (info.isVip
          ? this.priceData.serverPriceVip
          : this.priceData.serverPrice)
      )
    }
  },
  methods: {
    handleNum(val) {
      if (val < 0 && this.numVal === 1) {
        return
      }
      this.numVal += val
    },

    handlePay() {
      this.$emit('pay', {
        numVal: this.numVal,
        payMoney: this.payMoney
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.offer-price {
  width: 430px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .user-info {
    width: 386px;
    display: flex;
    align-items: center;
    .avatar {
      height: 42px;
      width: 42px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #f5f6f7;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .user-name {
      font-size: 18px;
      font-weight: normal;
      color: #000;
      margin-left: 12px;
    }
    .attention {
      width: 72px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: normal;
      color: #fff;
      background: #54c752;
      border-radius: 14px;
      margin-left: 28px;
    }
  }
  .offer-info {
    margin-top: 36px;
    width: 400px;
    text-align: left;
    .offer-title {
      font-family: Source Han Sans;
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }
    .price-content {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .base {
        font-family: Source Han Sans;
        font-size: 24px;
        font-weight: 500;
        color: #e35141;
        line-height: 1;
      }
      .vip {
        height: 24px;
        // width: 136px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        color: #ffffff;
        background-color: #d0974e;
        border-radius: 12px;
        margin-left: 12px;
        padding: 0 8px;
        box-sizing: border-box;
      }
      .service {
        font-family: Source Han Sans;
        font-size: 16px;
        color: #565455;
        line-height: 1;
        margin-left: 50px;
      }
    }
    .tips {
      display: flex;
      align-items: center;
      background: #eef8ed;
      border-radius: 4px;
      margin-top: 12px;
      padding: 0 10px;
      .auth-icon {
        position: relative;
        width: 22px;
        height: 22px;
        .icon-bg,
        .icon-main {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .tip-text {
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: 500;
        color: #04a101;
        margin-left: 10px;
      }
    }
  }
  .offer-form {
    text-align: left;
    width: 390px;
    .form-title {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
    .form-content {
      margin-top: 16px;
      padding: 20px 22px;
      .form-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
        .form-item-label,
        .form-item-value {
          font-family: Source Han Sans;
          font-size: 15px;
          font-weight: 500;
          color: #878787;
          flex-shrink: 0;
        }
        .form-item-value {
          color: #444;
          max-width: 242px;
          text-align: right;
        }
      }
    }
  }
  .count-info {
    display: flex;
    align-items: center;
    height: 76px;
    padding: 0 16px;
    width: 430px;
    .count-label {
      font-family: Source Han Sans;
      font-size: 18px;
      font-weight: 500;
      color: #878787;
    }
    .num-input {
      display: flex;
      align-items: center;
      justify-content: center;
      .input-button {
        width: 22px;
        height: 22px;
        position: relative;
        cursor: pointer;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .input-value {
        padding: 0 22px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 14px;
        color: #000000;
        margin: 0 12px;
        background: #f7f7f7;
        border: 1px solid #e7e7e7;
        border-radius: 6px;
      }
      .unit {
        font-family: Source Han Sans;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        color: #000000;
        margin-left: 12px;
      }
    }
    .pay-info {
      font-weight: 500;
      font-size: 14px;
      color: #e35141;
      line-height: 10px;
      margin-left: auto;
      .money {
        font-size: 24px;
        line-height: 10px;
      }
    }
  }
  .action-content {
    height: 68px;
    width: 430px;
    display: flex;
    align-items: stretch;
    .action-list {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 22px;
      .action-item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        .action-icon {
          width: 24px;
          height: 23px;
        }
        .action-name {
          font-family: Source Han Sans;
          font-size: 16px;
          font-weight: normal;
          color: #575757;
          margin-top: 6px;
        }
      }
    }
    .pay-button {
      width: 248px;
      background-color: #54c752;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
</style>