<template>
  <div class="comment-item" :class="{ reply: reply }">
    <div class="comment-user">
      <div class="user-avatar">
        <img :src="commentData.evaluateUser.avatar" />
      </div>
      <div class="comment-info">
        <div class="user-name">
          {{ commentData.evaluateUser.nickName }}
          <!-- <template v-if="commentData.isService">
            <div class="service-tag">服务商</div>
          </template> -->
          <template v-if="commentData.evaluateUser.isVip">
            <div class="vip-tag">会员</div>
          </template>
        </div>
        <div class="comment-time">{{ commentData.createTime }}</div>
      </div>
    </div>
    <div class="comment-content">
      <span class="reply-content" v-if="reply">回复</span>
      {{ commentData.childrenEvaluates.content }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CommentItem',
  props: {
    commentData: {
      type: Object,
      default: () => {
        return {
          avatar: '',
          username: 'ME的蓝天白云',
          content: '已经不是第一次合作了，设计能力相当了得。下一个开发季再见!',
          time: '2024.06.10 17:03',
          isVip: true,
          isService: true
        }
      }
    },
    reply: {
      tpye: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-item {
  padding: 24px 0;
  text-align: left;
  border-bottom: 1px solid #f7f7f7;
  &:last-child {
    border-bottom: none;
  }
  .comment-user {
    display: flex;
    .user-avatar {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #f5f6f7;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .comment-info {
      margin-left: 12px;
      .user-name {
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        .service-tag {
          width: 48px;
          height: 18px;
          border-radius: 6px;
          background: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Source Han Sans;
          font-size: 12px;
          font-weight: normal;
          color: #ffffff;
          margin-left: 12px;
        }
        .vip-tag {
          width: 36px;
          height: 18px;
          border-radius: 6px;
          background: #d0974e;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Source Han Sans;
          font-size: 12px;
          font-weight: normal;
          color: #ffffff;
          margin-left: 12px;
        }
      }
      .comment-time {
        font-family: Source Han Sans;
        font-size: 16px;
        font-weight: normal;
        color: #b0b0b0;
      }
    }
  }
  .comment-content {
    margin-top: 2px;
    font-family: Source Han Sans;
    font-size: 16px;
    font-weight: normal;
    color: #4d4d4d;
  }
}
.reply {
  margin-top: 24px;
  border-radius: 12px;
  padding: 16px 32px;
  padding-bottom: 24px;
  background-color: rgba(216, 216, 216, 0.1);
  .reply-content {
    font-family: Source Han Sans;
    font-size: 16px;
    font-weight: normal;
    color: #b0b0b0;
    margin-right: 8px;
  }
}
</style>