<template>
  <div class="w-full">
    <!-- 背景 -->
    <div class="relative">
      <img src="@/assets/homeBg.svg" />
      <div
        class="absolute top-5 right-5 cursor-pointer"
        @click="$router.push('/my/infoEdit')"
      >
        <img src="@/assets/homeEdit.svg" />
      </div>
    </div>
    <!-- 信息 -->
    <div class="pl-16 pr-32 flex justify-between items-center">
      <div class="relative flex mt-3.5">
        <div class="w-32 h-2">
          <img
            class="w-32 h-32 rounded-full border-2 border-white absolute -top-16"
            :src="userInfo.avatar"
          />
        </div>
        <div>
          <div class="flex">
            <div class="text-2xl text-neutral-700">{{ userInfo.nickName }}</div>
            <div
              class="mx-5 w-24 h-[28px] rounded-[14px] bg-[#54C752] text-base text-white flex items-center justify-center cursor-pointer"
            >
              关注
            </div>
          </div>
          <div class="text-left text-[#3D3D3D]">
            这个人很懒，什么都没有留下。
          </div>
        </div>
      </div>
      <homeNum :info="userInfo"></homeNum>
    </div>
    <!-- 标签页 -->
    <homeTab
      :tabIndex="tabIndex"
      :sortIndex="sortIndex"
      @tab="tabChange"
      @sort="sortChange"
    ></homeTab>
    <div class="mt-[38px]">
      <!-- AI作品 -->
      <homeTabAi v-if="tabIndex == 0"></homeTabAi>
      <!-- 合集 -->
      <homeTabCollection
        v-if="tabIndex == 1"
        :isMineView="isMineView"
      ></homeTabCollection>
      <!-- 笔记 -->
      <homeTabNote v-if="tabIndex == 2"></homeTabNote>
      <!-- 服务 -->
      <homeTabService v-if="tabIndex == 3"></homeTabService>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/user'
import homeNum from '../components/homeNum.vue'
import homeTab from '../components/homeTab.vue'
import homeTabAi from '../components/homeTabAi.vue'
import homeTabNote from '../components/homeTabNote.vue'
import homeTabService from '../components/homeTabService.vue'
import homeTabCollection from '../components/homeTabCollection.vue'
export default {
  name: 'home',
  components: {
    homeNum,
    homeTab,
    homeTabAi,
    homeTabNote,
    homeTabService,
    homeTabCollection
  },
  data() {
    return {
      tabIndex: 0,
      sortIndex: 0,
      isMineView: false, // 是否是创作者自己视角
      userInfo: {}
    }
  },
  mounted() {
    const id = this.$route.params.id
    if (!id && localStorage.getItem('authorization')) {
      this.isMineView = true
      this.userInfo = getUserInfo()
    }
  },
  methods: {
    /* 切换标签 */
    tabChange(index) {
      this.tabIndex = index
      this.sortIndex = 0
    },

    /* 切换排序 */
    sortChange(index) {
      this.sortIndex = index
    }
  }
}
</script>

<style>
</style>