<template>
  <div class="commonLayout">
    <!-- header -->
    <div class="flex_center_between">
      <div class="flex_">
        <div v-if="title" class="commonLayout-title">{{ title }}</div>
        <div class="flex_">
          <div
            class="commonLayout-tab"
            v-for="(item, index) in tabList"
            :key="index"
            :class="{ active_router: currentKey == item.key }"
            @click="handleTab(item.key)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="flex_">
        <slot name="header-right">
          <div class="commonLayout-button" style="width: 200px; padding: 0 9px">
            <img class="img" src="@/assets/collection/arrow-search.svg" />
            <el-input
              v-model="searchKey"
              placeholder="搜索画面描述"
              @change="handleSearch"
            />
          </div>
          <div
            v-if="chooseBtnVisible && !isChoose"
            class="commonLayout-button"
            @click="handleChoose(true)"
          >
            <div>选择内容</div>
          </div>
          <div
            v-if="chooseBtnVisible && isChoose"
            class="commonLayout-button isChoose"
            @click="handleChoose(false)"
          >
            <div>选择内容</div>
          </div>
          <div v-if="filterBtnVisible" class="commonLayout-button">
            <div>筛选</div>
            <img class="img" src="@/assets/collection/arrow-shaixuan.svg" />
          </div>
        </slot>
      </div>
    </div>
    <!-- content -->
    <div :style="{ 'margin-top': mt + 'px' }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonLayout',
  props: {
    /* 页面标题 */
    title: {
      type: String,
      default: null
    },
    /* 标题旁的tab列表: title-标题, key-关键值 */
    tabList: {
      type: Array,
      default: () => []
    },
    /* 默认选中tab关键值 */
    defaultTab: {
      type: [String, Number],
      default: null
    },
    /* 是否显示选择内容按钮 */
    chooseBtnVisible: {
      type: Boolean,
      default: false
    },
    /* 是否显示筛选按钮 */
    filterBtnVisible: {
      type: Boolean,
      default: false
    },
    /* 页面内容间距 */
    mt: {
      type: [Number, String],
      default: 24
    }
  },
  data() {
    return {
      isChoose: false,
      searchKey: null,
      currentKey: 1
    }
  },
  mounted() {
    let { defaultTab, tabList } = this
    if (defaultTab) {
      this.currentKey = defaultTab
    } else if (tabList.length) {
      this.currentKey = tabList[0].key
    }
    this.handleTab(this.currentKey)
  },
  methods: {
    /**
     * tab改变事件
     * @emit tab(currentKey)
     */
    handleTab(key) {
      this.isChoose = false
      this.searchKey = null
      this.currentKey = key
      this.$emit('tab', this.currentKey)
    },
    /**
     * 搜索画面描述
     * @emit search(searchKey)
     */
    handleSearch() {
      this.$emit('search', this.searchKey)
    },
    /**
     * 点击选择内容
     * @emit choose(isChoose)
     */
    handleChoose(status) {
      this.isChoose = status
      this.$emit('choose', this.isChoose)
    }
  }
}
</script>

<style lang="scss" scoped>
.commonLayout {
  width: 1402px;
  margin: 0 auto;
  &-title {
    font-size: 40px;
    font-weight: bold;
    color: #3d3d3d;
    margin-right: 68px;
  }
  &-tab {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    margin-right: 35px;
    cursor: pointer;
  }
  &-button {
    width: 110px;
    height: 33px;
    border-radius: 50px;
    border: 1px solid #c4bfbf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #000000;
    cursor: pointer;
    .img {
      margin-left: 10px;
    }
  }
  &-button + &-button {
    margin-left: 22px;
  }
  .isChoose {
    color: #ffffff;
    background: #d000ff;
  }
}
.flex_ {
  display: flex;
  align-items: center;
}
.flex_center_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active_router {
  @apply font-black relative;
  color: #000;
  &::after {
    @apply block absolute right-0.5 -bottom-1 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
::v-deep .el-input__inner {
  border: none;
  height: 30px;
}
::v-deep .el-input__inner:hover {
  border-color: #fff;
}
</style>