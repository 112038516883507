<template>
  <div class="albumCenter">
    <!-- head -->
    <div class="flex_center_between">
      <div class="flex_">
        <div class="albumCenter-title">我的创作</div>
        <div class="flex_">
          <div
            class="albumCenter-tab"
            :class="{ active_router: currentKey == 1 }"
            @click="handleTab(1)"
          >
            全部
          </div>
          <div
            class="albumCenter-tab"
            :class="{ active_router: currentKey == 2 }"
            @click="handleTab(2)"
          >
            合集
          </div>
        </div>
      </div>
      <albumHeadRight
        :currentKey="currentKey"
        @status="handleStatus"
        @select="handleSelect"
        @search="handleSearch"
        @statusAll="handleStatusAll"
        @join="$refs.albumList.handleAdjust('join')"
        @load="$refs.albumList.handleLoad()"
        @deleteAlbum="$refs.albumList.handleDel()"
        @delete="$refs.albumCoverList.handleDel()"
        @add="$refs.albumCoverForm.openAddDialog(false)"
      ></albumHeadRight>
    </div>
    <!-- list -->
    <albumList
      ref="albumList"
      v-if="currentKey == 1"
      :albumList="albumList"
      @fresh="handleInit"
      @join="handleAlbumListJoin"
    ></albumList>
    <albumCoverList
      ref="albumCoverList"
      v-if="currentKey == 2"
      v-loading="loading"
      :albumList="albumList"
      @fresh="handleInit"
    ></albumCoverList>
    <!-- 新建合集/合集设置 -->
    <albumCoverForm ref="albumCoverForm" @fresh="handleInit"></albumCoverForm>
    <!-- 加入合集 -->
    <albumCoverJoin
      ref="albumCoverJoin"
      @fresh="handleSelect(true)"
    ></albumCoverJoin>
  </div>
</template>

<script>
import albumList from '../components/albumList.vue'
import albumHeadRight from '../components/albumHeadRight.vue'
import albumCoverList from '../components/albumCoverList.vue'
import albumCoverForm from '../components/albumCoverForm.vue'
import albumCoverJoin from '../components/albumCoverJoin.vue'
import {
  userImageStorage,
  userCollectionList,
  userCollectionImgList
} from '@/api/album/index.js'
export default {
  name: 'albumCenter',
  components: {
    albumList,
    albumHeadRight,
    albumCoverList,
    albumCoverForm,
    albumCoverJoin
  },
  data() {
    return {
      searchKey: null,
      albumList: [],
      initAlbumList: [],
      currentKey: 1,
      loading: false
    }
  },
  mounted() {
    this.handleTab(1)
  },
  methods: {
    /* 点击tab */
    handleTab(key) {
      this.currentKey = key
      this.handleInit()
    },

    /* 搜索 */
    handleSearch(key) {
      this.searchKey = key
      this.handleInit()
    },

    /* 初始化 */
    handleInit() {
      this.loading = true
      let myApi = this.currentKey == 1 ? userImageStorage : userCollectionList
      myApi({ page: 1, limit: -1, prompt: this.searchKey })
        .then(async (res) => {
          let list = (res && res.rows) || []
          if (this.currentKey == 2) {
            // 合集
            for (let i in list) {
              let result = await userCollectionImgList({
                collectionArticleId: list[i].id,
                page: 1,
                limit: 6
              }).catch((e) => {})
              list[i].IMGLIST = result.rows || []
              list[i].IMGTOTAL = result.total
            }
          }
          this.albumList = list
          this.initAlbumList = JSON.parse(JSON.stringify(list))
          console.log('初始化', list)
        })
        .finally(() => {
          this.loading = false
        })
    },

    /* 发布状态 */
    handleStatus(status) {
      if (status == 1) {
        this.albumList = this.initAlbumList.filter((v) => v.isOpen == 1)
      } else if (status == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.initAlbumList))
      } else {
        this.albumList = this.initAlbumList.filter((v) => v.isOpen != 1)
      }
    },
    handleStatusAll(status) {
      if (status == 1) {
        this.albumList = this.initAlbumList.filter((v) => v.issue)
      } else if (status == -1) {
        this.albumList = JSON.parse(JSON.stringify(this.initAlbumList))
      } else {
        this.albumList = this.initAlbumList.filter((v) => !v.issue)
      }
    },

    /* 选择 */
    handleSelect(status) {
      if (this.currentKey == 1) {
        this.$refs.albumList.initStatus(status)
      }
      if (this.currentKey == 2) {
        this.$refs.albumCoverList.initStatus(status)
      }
    },

    /* 全部-加入合集 */
    handleAlbumListJoin(checkList) {
      this.$refs.albumCoverJoin.openJoinDialog(checkList)
    }
  }
}
</script>

<style lang="scss" scoped>
.albumCenter {
  width: 1402px;
  margin: 0 auto;
  &-title {
    font-size: 40px;
    font-weight: bold;
    color: #3d3d3d;
    margin-right: 68px;
  }
  &-tab {
    font-size: 18px;
    font-weight: 500;
    color: #666666;
    margin-right: 35px;
    cursor: pointer;
  }
}
.flex_ {
  display: flex;
  align-items: center;
}
.flex_center_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active_router {
  @apply font-black relative;
  color: #000;
  &::after {
    @apply block absolute right-0.5 -bottom-1 h-1;
    content: '';
    width: 20px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
</style>