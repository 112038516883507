<template>
  <div class="grid views">
    <div
      class="mt-2 w-[865px] h-[376px] p-4 bg-[#2c2c2c] rounded-xl z-[900] relative col-span-3"
    >
      <div class="flex justify-start items-center mb-4">
        <div
          v-for="(item, index) in recommendList"
          :key="index"
          class="mr-4 bg-black text-[#C3D1E3] px-3 py-1 rounded cursor-pointer text-xl font-medium"
          :class="activeRecommend === item.key && 'activeRecommend'"
          @click="changeRecommend(item.key)"
        >
          {{ item.title }}
        </div>
        <div class="text-[#C3D1E3] ml-6 text-lg font-light">
          {{
            recommendList.find((item) => item.key === activeRecommend)?.slogan
          }}
        </div>
      </div>
      <div v-if="activeRecommend === 'recommend'">
        <!-- 服务商推荐 -->
        <div>
          <div
            class="w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative bg"
          >
            <div
              class="bg-[#000] w-full h-52 rounded-xl absolute bottom-0 flex justify-center items-center"
            >
              <div
                class="rounded-full w-28 h-28 border border-[#F7E296] border-4 absolute left-1/2 -ml-14 -top-14"
              >
                <img src="@/assets/user/head.png" alt="" />
              </div>
              <div
                class="mx-auto text-[#C3D1E3] absolute bottom-2 w-48 flex flex-col justify-center items-center"
              >
                <div class="font-bold">无名老角</div>
                <div
                  class="mt-2 bg-[#54C752] w-16 h-6 text-white rounded-xl cursor-pointer"
                >
                  关注
                </div>
                <div class="mt-2 text-xs line-clamp-2 overflow-hidden">
                  我是无名老角
                </div>
                <div class="flex mt-2">
                  <div class="flex flex-col items-center justify-center mr-2">
                    <span class="text-sm font-bold">96</span>
                    <span class="text-xs">关注</span>
                  </div>
                  <div class="flex flex-col items-center justify-center mr-2">
                    <span class="text-sm font-bold">128</span>
                    <span class="text-xs">粉丝</span>
                  </div>
                  <div class="flex flex-col items-center justify-center mr-2">
                    <span class="text-sm font-bold">1053</span>
                    <span class="text-xs">获赞</span>
                  </div>
                  <div class="flex flex-col items-center justify-center mr-2">
                    <span class="text-sm font-bold">103</span>
                    <span class="text-xs">发布</span>
                  </div>
                  <div class="flex flex-col items-center justify-center mr-2">
                    <span class="text-sm font-bold">10</span>
                    <span class="text-xs">被同款</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeRecommend === 'hotProject'">
        <!-- 热门项目 -->
        <div class="hotProject max-w-[870px] flex overflow-auto scrollbar pb-2">
          <div
            class="w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative mr-4 shrink-0 hotProjectChild"
            @click="
              item.articleType == 2
                ? $router.push(`/works/collection/${item.id}`)
                : $router.push(`/works/detail/${item.id}`)
            "
            :style="{
              background: 'url(' + item.coverImgUrl + ')',
              backgroundSize: 'cover'
            }"
            v-for="(item, index) in hotProjectList"
            :key="index"
          >
            <div class="flex mt-2 px-2 justify-start">
              <img
                src="@/assets/user/head.png"
                alt=""
                class="w-6 h-6 rounded-full"
              />
              <span class="ml-2 text-white text-sm">EVA1990</span>
            </div>
            <div class="p-2 absolute bottom-2">
              <div
                class="w-20 h-5 bg-black text-white text-sm text-center rounded-xl"
              >
                服务100次
              </div>
              <div
                class="bg-[rgba(0,0,0.13,.6)] w-52 h-9 rounded-md text-lg text-white mt-2 ml-1 leading-9 cursor-pointer"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeRecommend === 'hotPage'">
        <!-- 热门笔记 -->
        <div class="hotPage">
          <div
            class="w-60 h-72 border border-[#F7E296] border-4 rounded-xl relative"
          >
            <div class="flex mt-2 px-2 justify-start">
              <img src="" alt="" class="w-6 h-6 rounded-full" />
              <span class="ml-2 text-white text-sm">TESTNAME</span>
            </div>
            <div class="p-2 absolute bottom-2 line-clamp-3 text-white">
              这里显示笔记还是看出可惜那时课程难度就卡死你才可能农村库拉索尼查看蓝色看来你吃辣看出考虑·拿出来看三层楼看似冷酷才能卢卡斯内存卡内存颗粒大女除了看到吕尼
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="w-[282px] h-[390px]" v-if="activeSearchKeys === '-2'"></div>
        <div class="w-[282px] h-[390px]" v-if="activeSearchKeys === '-2'"></div> -->
    <div
      class="mt-2 rounded-xl cursor-pointer group"
      v-for="(item, index) in articleList"
      :key="index"
      @click="$router.push(`/service/detail/${item.id}`)"
    >
      <PreviewCard
        :sourceId="item.id"
        :cover="item.coverImgUrl"
        :title="item.serverTitle"
        :price="item.serverPrice"
        :serviceNum="item.serverCount"
        :service="true"
        :isMask="false"
        :isLike="false"
        :isVipCount="true"
      />
    </div>
    <!-- </template>
      </waterfall> -->
  </div>
</template>

<script>
import { findMiniList } from '@/api/home'
import { getProviderServerList } from '@/api/service/index'
import PreviewCard from '@/components/previewCard'

export default {
  name: 'ScrollContent',
  components: {
    PreviewCard
  },
  data() {
    return {
      activeRecommend: 'recommend',
      recommendList: [
        {
          title: '服务商推荐',
          slogan: '专业的服务商，为您的产品落地保驾护航',
          key: 'recommend'
        },
        {
          title: '热门项目',
          slogan: '被同行千万次验证过的服务，良心推荐！',
          key: 'hotProject'
        },

        {
          title: '热门笔记',
          slogan: '优质高人气内容给你创作灵感',
          key: 'hotPage'
        }
      ],
      articleList: [],
      hotProjectList: [],
      pageNum: 1
    }
  },
  methods: {
    changeRecommend(val) {
      this.activeRecommend = val
    },
    // 根据当前类型获取列表数据
    async getList(change = false) {
      const result = await getProviderServerList({
        page: this.pageNum,
        limit: -1,
        auditStatus: 1
      })
      if (!change) {
        this.articleList = result.rows
      } else {
        this.articleList.push(...result?.rows)
      }
    },
    // 临时代码，热门项目
    async getHotProject(change = false) {
      const result = await findMiniList({
        page: this.pageNum
      })
      let list = result.rows
      this.hotProjectList = list.splice(0, 5)
    }
  },
  mounted() {
    this.getList()
    this.getHotProject()
  }
}
</script>

<style lang="scss" scoped>
.activeRecommend {
  background: linear-gradient(90deg, #f7e296 0%, #fffbeb 50%, #f3de94 99%);
  color: #000 !important;
}
.hotProjectChild,
.hotPage {
  &:first-child::before {
    content: '';
    position: absolute;
    display: block;
    margin-left: 160px;
    width: 60px;
    height: 60px;
    background: url('@/assets/first.svg') no-repeat;
    background-size: cover;
    z-index: 999;
  }
  &:nth-child(2):before {
    content: '';
    position: absolute;
    display: block;
    margin-left: 160px;
    width: 60px;
    height: 60px;
    background: url('@/assets/second.svg') no-repeat;
    background-size: cover;
    z-index: 999;
  }
  &:nth-child(3)::before {
    content: '';
    position: absolute;
    display: block;
    margin-left: 160px;
    width: 60px;
    height: 60px;
    background: url('@/assets/third.svg') no-repeat;
    background-size: cover;
    z-index: 999;
  }
}
.bg {
  background: url('@/assets/bg.png');
}
.scrollbar::-webkit-scrollbar {
  width: 4px; /*  设置纵轴（y轴）轴滚动条 */
  height: 4px;
  margin-top: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #a8a8a8;
}
.scrollbar::-webkit-scrollbar-track {
  background: #000;
}
.views {
  grid-template-columns: repeat(6, 295px);
}
</style>