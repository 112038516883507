<template>
  <div>
    <shejiDialog v-model="value" height="800px">
      <div class="px-3">
        <div class="text-xl text-left">上传配件</div>
        <div class="mt-4">
          <div class="text-left text-[#878787] text-sm mb-2">* 配件图片</div>
          <div class="flex flex-wrap gap-5">
            <div>
              <div
                v-for="(item, index) in images"
                :key="index"
                class="w-[120px] p-3 cursor-pointer flex flex-col justify-center items-center"
              >
                <img :src="item.url" class="w-full h-24 object-cover" alt="" />
                <el-input v-model="item.name" class="mt-2"></el-input>
              </div>
            </div>
            <el-upload
              action="https://www.sheji-ai.cc/systemApi/files-anon"
              :on-success="handleFileUpload"
              :before-upload="checkFile"
              :show-file-list="false"
            >
              <div
                slot="trigger"
                class="w-[120px] h-36 p-3 rounded cursor-pointer border flex flex-col justify-center items-center"
              >
                <img src="@/assets/image.svg" class="w-12 h-12" alt="" />
                <div class="text-[#0033FF] text-sm mt-1">上传配件图片</div>
                <div class="text-[#878787] text-xs mt-1">
                  建议图片像素1080P, 格式JPG/PNG, 大小不超过2MB
                </div>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="p-2 border-t mt-2">
          <div class="flex items-center justify-start mt-5">
            <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
              * 配件编号
            </div>
            <el-input v-model="form.partsNo"></el-input>
            <div class="ml-2 w-36 text-sm flex"></div>
          </div>
          <div class="flex items-center justify-start mt-5">
            <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
              * 配件材质
            </div>
            <el-select v-model="form.partsTextureId">
              <el-option
                v-for="(item, index) in material"
                :key="index"
                :label="item.textureName"
                :value="item.id"
              ></el-option>
            </el-select>
            <div class="ml-2 w-36 text-sm flex cursor-pointer">
              <img src="@/assets/borderPlus.svg" alt="" srcset="" />
              <span class="ml-1" @click="openAddMaterial">创建新材质</span>
            </div>
          </div>
          <div class="flex items-center justify-start mt-5">
            <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
              * 配件类别
            </div>
            <el-select v-model="form.partsClassesId">
              <el-option
                v-for="(item, index) in category"
                :key="index"
                :label="item.classifyName"
                :value="item.id"
              ></el-option>
            </el-select>
            <div class="ml-2 w-36 text-sm flex cursor-pointer">
              <img src="@/assets/borderPlus.svg" alt="" srcset="" />
              <span class="ml-1" @click="openAddClasses">创建新类别</span>
            </div>
          </div>
          <div class="flex items-center justify-start mt-5">
            <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
              * 我的分类
            </div>
            <el-select v-model="form.partsClassifyId">
              <el-option
                v-for="(item, index) in classes"
                :key="index"
                :label="item.classesName"
                :value="item.id"
              ></el-option>
            </el-select>
            <div class="ml-2 w-36 text-sm flex cursor-pointer">
              <img src="@/assets/borderPlus.svg" alt="" srcset="" />
              <span class="ml-1" @click="openAddCategory">创建我的分类</span>
            </div>
          </div>
          <div class="flex items-center justify-start mt-5">
            <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
              * 供应商
            </div>
            <el-select
              v-model="form.providerId"
              filterable
              remote
              :remote-method="getProvider"
            >
              <el-option
                v-for="(item, index) in provider"
                :key="index"
                :label="item.companyName"
                :value="item.id"
              ></el-option>
            </el-select>
            <div class="ml-2 w-36 text-sm flex cursor-pointer">
              <img src="@/assets/borderPlus.svg" alt="" srcset="" />
              <span class="ml-1" @click="openProvide = true">创建新供应商</span>
            </div>
          </div>
          <div class="flex items-center justify-start mt-5">
            <div class="text-left w-28 mr-2 mt-2 text-[#878787] text-sm mb-2">
              产品介绍
            </div>
            <el-input type="textarea" v-model="form.partsIntroduce"></el-input>
            <div class="ml-2 w-36 text-sm flex"></div>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center mt-4">
        <div
          class="w-72 h-10 leading-10 bg-[#F4F5F7] rounded-3xl cursor-pointer"
          @click="saveParts(1)"
        >
          保存到我的库
        </div>
        <div
          class="w-72 h-10 text-sm text-center bg-[#54C752] text-white rounded-3xl ml-3 cursor-pointer"
          @click="saveParts()"
        >
          <div>保存并同步到系统</div>
          <div class="flex justify-center text-xs">
            （送
            <img src="@/assets/electron.svg" class="w-3 h-3" alt="" srcset="" />
            +10电力券）
          </div>
        </div>
      </div>
    </shejiDialog>
    <addProvide v-model="openProvide" v-if="openProvide"></addProvide>
  </div>
</template>
  
  <script>
import shejiDialog from '@/components/shejiDialog'
import addProvide from '../addProvide'
import {
  addCategory,
  addClass,
  addTexture,
  getClassList,
  getMaterialList,
  getProvider,
  getUserAllPartCategory,
  uploadParts
} from '@/api/workbench'
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  components: {
    shejiDialog,
    addProvide
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  data() {
    return {
      images: [],
      material: [],
      category: [],
      classes: [],
      provider: [],
      openProvide: false,
      form: {
        partsNo: '',
        partsTextureId: '',
        partsClassesId: '',
        partsClassifyId: '',
        providerId: '',
        partsIntroduce: ''
      }
    }
  },
  mounted() {
    this.getMaterial()
    this.getCategory()
    this.getClasses()
  },
  methods: {
    // 检查文件格式
    checkFile(file) {
      const isFormat =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 200

      if (!isFormat) {
        this.$message.error('上传的图片只能是 PNG、JPG、JPEG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传的图片大小不能超过 200MB!')
      }
      const result = isFormat && isLt2M
      if (result) {
        this.fileLoading = true
        return true
      }
      return false
    },
    // 处理图片上传
    handleFileUpload(val) {
      console.log(val)
      this.$message.success('上传成功')
      this.images.push({ ...val, name: '' })
      this.fileLoading = false
    },
    // 获取材质
    async getMaterial() {
      const result = await getMaterialList()
      if (result) {
        this.material = result.data
        console.log(this.material)
      }
    },
    // 获取类别
    async getClasses() {
      const result = await getClassList()
      if (result) {
        this.classes = result.data
        console.log(this.classes)
      }
    },
    // 获取分类
    async getCategory() {
      const result = await getUserAllPartCategory()
      if (result) {
        this.category = result.data
        console.log(this.category)
      }
    },
    /** 获取供应商 */
    async getProvider(name) {
      if (name !== '') {
        const result = await getProvider({
          providerName: name
        })
        if (result) {
          this.provider = result.rows
          console.log(this.provider)
        }
      }
    },
    /** 保存配件 */
    async saveParts(isSystem = 0) {
      if (this.images.length === 0) {
        this.$message.error('请上传图片')
        return
      } else {
        for (let o in this.images) {
          if (!this.images[o].name) {
            this.$message.error('请输入配件项描述')
            return
          }
        }
      }
      for (let i in this.form) {
        if (i !== 'partsIntroduce' && !this.form[i]) {
          this.$message.error('参数错误')
          return
        }
      }
      const params = {
        partsUserItems: this.images.map((item, index) => {
          return {
            itemImgUrl: item.url,
            itemIntroduce: item.name,
            sort: index
          }
        }),
        partsNo: this.form.partsNo,
        partsTextureId: this.form.partsTextureId,
        partsClassesId: this.form.partsClassesId,
        partsClassifyId: this.form.partsClassifyId,
        providerId: this.form.providerId,
        partsIntroduce: this.form.partsIntroduce,
        isSyncSystem: isSystem
      }
      const result = await uploadParts(params)
      if (result) {
        this.$message.success('添加成功')
        this.$emit('refresh')
        this.value = false
      }
    },
    openAddMaterial() {
      this.$prompt('请输入材质名称', '新增材质', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async ({ value }) => {
          await addTexture({ textureName: value })
          this.$message.success('添加成功')
          this.getMaterial()
          this.$emit('refresh')
        })
        .catch(() => {})
    },
    openAddClasses() {
      this.$prompt('请输入类别名称', '新增类别', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async ({ value }) => {
          await addClass({ classesName: value })
          this.$message.success('添加成功')
          this.getClasses()
          this.$emit('refresh')
        })
        .catch(() => {})
    },
    openAddCategory() {
      this.$prompt('请输入分类名称', '新增我的分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async ({ value }) => {
          await addCategory({ classifyName: value })
          this.$message.success('添加成功')
          this.getCategory()
          this.$emit('refresh')
        })
        .catch(() => {})
    }
  }
}
</script>
  
  <style lang="scss" scoped>
::v-deep .el-upload {
  display: block;
}
::v-deep .el-input__inner {
  width: 100% !important;
  height: 35px !important;
  background: #f4f5f7 !important;
  border: 0 !important;
}
::v-deep .el-textarea__inner {
  background: #f4f5f7 !important;
  border: 0 !important;
}
::v-deep .el-select {
  width: 100%;
}
</style>