<template>
  <div>
    <el-checkbox-group v-model="checkList" @change="handleCheckbox">
      <div v-if="albumList.length" class="albumInfoList" v-loading="loading">
        <div
          class="albumInfoList-item"
          v-for="(item, index) in albumList"
          :key="index"
        >
          <albumInfoItem
            :isSelected="isSelected"
            :imgUrl="item.imgUrl"
            :issue="item.issue"
            :checkValue="item.id"
            :showMask="checkList.includes(item.id)"
          ></albumInfoItem>
        </div>
      </div>
      <el-empty v-else :image-size="200"></el-empty>
    </el-checkbox-group>
    <!-- 删除弹窗 -->
    <comfirmDialog
      v-model="delDialogVisible"
      title="确认删除"
      content="确认删除后生成作品将无法恢复"
      cancelTxt="取消"
      @confirm="handleConfirmDel"
    ></comfirmDialog>
  </div>
</template>

<script>
import albumInfoItem from './albumInfoItem.vue'
import comfirmDialog from '@/components/comfirmDialog/index'
import { delUserImageStorage } from '@/api/album/index.js'
export default {
  name: 'albumList',
  components: { albumInfoItem, comfirmDialog },
  props: {
    albumList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkList: [],
      loading: false,
      isSelected: false,
      delDialogVisible: false
    }
  },
  methods: {
    /* 选择 */
    initStatus(status) {
      this.isSelected = status
      this.checkList = []
    },

    /* 多选 */
    handleCheckbox(e) {
      console.log('handleCheckbox', e, this.checkList)
    },

    /* 选择判断 */
    handleAdjust(eventName) {
      if (!this.checkList.length) {
        return this.$message.error('请至少选择一个作品')
      }
      this.$emit(eventName, this.checkList)
    },

    /* 删除 */
    handleDel() {
      if (!this.checkList.length) {
        return this.$message.error('请至少选择一个合集')
      }
      this.delDialogVisible = true
    },
    handleConfirmDel() {
      delUserImageStorage(this.checkList).then((res) => {
        if (res.code != 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.checkList = []
        this.delDialogVisible = false
        this.$emit('fresh')
      })
    },

    /* 下载 */
    handleLoad() {
      if (!this.checkList.length) {
        return this.$message.error('请至少选择一个作品')
      }
      this.checkList.map((v, i) => {
        let item = this.albumList.find((d) => d.id == v)
        if (item) {
          this.downLoadFile(item.imgUrl)
        }
      })
      this.checkList = []
    },
    async downLoadFile(imageUrl) {
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `作品.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.albumInfoList {
  width: 100%;
  margin-top: 33px;
  display: flex;
  flex-wrap: wrap;
  &-item {
    width: 220px;
    margin-right: 14px;
  }
  &-item:nth-child(6n) {
    margin-right: 0;
  }
}
</style>