<template>
  <div>
    <layout
      leftContentName="参数设置"
      @generator="generator"
      @revocation="revocation"
      @regenerator="regenerator"
      @save="save"
      @update="update"
      :couponInfo="couponInfo"
      :result="taskInfo"
      :isWait="isWait"
      type="conditiongeneration"
    >
      <template slot="firstTag">
        <div class="p-4" v-loading="fileLoading">
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">条件选择</div>
            <div class="flex">
              <div
                class="w-32 h-7 px-4 bg-[#F4F5F7] rounded-3xl mr-2 cursor-pointer"
                :class="
                  conditionType === 1 && conditions.length > 0
                    ? 'conditionActive'
                    : ''
                "
                @click="
                  conditionVisiable = true
                  conditionType = 1
                  conditions = []
                "
              >
                随机
              </div>
              <div
                class="w-32 h-7 px-4 bg-[#F4F5F7] rounded-3xl mr-2 cursor-pointer"
                :class="
                  conditionType === 2 && conditions.length > 0
                    ? 'conditionActive'
                    : ''
                "
                @click="
                  conditionVisiable = true
                  conditionType = 2
                "
              >
                品类
              </div>
              <div
                class="w-32 h-7 px-4 bg-[#F4F5F7] rounded-3xl cursor-pointer"
                :class="
                  conditionType === 3 && conditions.length > 0
                    ? 'conditionActive'
                    : ''
                "
                @click="
                  conditionVisiable = true
                  conditionType = 3
                "
              >
                场景
              </div>
            </div>
            <div
              v-if="conditions.length > 0"
              class="mt-4 border border-[#54C752] rounded-xl text-left px-1 py-1"
            >
              已选择：
              {{ conditions.map((item) => item.instructName).join(',') }}
            </div>
          </div>
          <el-divider></el-divider>
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">
              色彩选择（选填）
            </div>
            <div class="flex">
              <el-popconfirm
                v-for="(item, index) in colors"
                :key="item"
                title="确定删除该颜色吗？"
                @confirm="removeColor(index)"
              >
                <div
                  slot="reference"
                  class="w-14 h-14 rounded-lg mr-1 mb-1 cursor-pointer"
                  :style="{ background: item }"
                ></div>
              </el-popconfirm>

              <div
                class="w-14 h-14 rounded-lg bg-[#F0F0F0] flex items-center justify-center cursor-pointer"
                @click="colorVisiable = true"
              >
                <img src="@/assets/plus.svg" alt="" />
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div>
            <div class="text-left text-[#666666] text-sm mb-3">
              补充描述（选填）
            </div>
            <div class="flex">
              <div class="flex flex-col items-start">
                <div class="text-left text-[#666666] text-sm">添加参考图</div>
                <div class="w-full mt-2 flex justify-start">
                  <el-upload
                    action="https://www.sheji-ai.cc/systemApi/files-anon"
                    :on-success="handleFileUpload"
                    :before-upload="checkFile"
                    :show-file-list="false"
                    :size="'40%'"
                  >
                    <div
                      class="w-28 h-28 rounded-lg flex justify-center items-center bg-[#F4F5F7]"
                      v-if="JSON.stringify(images) === '{}'"
                    >
                      <img src="@/assets/photo.svg" alt="" />
                    </div>
                    <div class="w-28 h-28 rounded-lg relative" v-else>
                      <div>
                        <img
                          :src="images.url"
                          class="w-28 h-28 rounded-lg"
                          alt=""
                        />
                      </div>
                    </div>
                  </el-upload>
                </div>
              </div>
              <div class="flex flex-1 flex-col items-start ml-2">
                <div class="text-left text-[#666666] text-sm">添加文本描述</div>
                <div class="w-full mt-2">
                  <ElInput
                    v-model="promptCn"
                    type="textarea"
                    :rows="5"
                    maxlength="1000"
                    showWordLimit
                    placeholder="输入你想要补充的内容，支持中英文。"
                    clearable
                  ></ElInput>
                </div>
              </div>
            </div>
            <div
              class="w-full flex items-center mt-3"
              v-if="JSON.stringify(images) !== '{}'"
            >
              <div class="w-[100px] text-sm font-light mr-3">参考图权重</div>
              <div class="w-full">
                <el-slider v-model="weight"></el-slider>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <basicSetting @update="updateSetting" />
        </div>
      </template>
    </layout>
    <colorSelector v-model="colorVisiable" @update="addColor"></colorSelector>
    <conditionSelector
      v-model="conditionVisiable"
      :type="conditionType"
      @update="updateCondition"
      :selectionData="conditions"
    ></conditionSelector>
  </div>
</template>
    
    <script>
import { createJob } from '@/api/workbench'
import layout from '../components/layout'
import colorSelector from '../components/colorSelector'
import conditionSelector from '../components/conditionSelector'
import basicSetting from '../components/basicSetting'
import mixins from '../mixin'

export default {
  methods: {
    /** 生成 */
    async generator() {
      if (this.conditions.length === 0) {
        this.$message.error('请选择条件！')
        return
      }
      const extend = {
        condition: this.conditions,
        color: this.colors.length > 0 ? this.colors : undefined
      }

      const params = {
        taskType: 8,
        promptCn: this.promptCn,
        reversePrompt: this.reversePrompt,
        aspectRatio: this.ratio,
        modelTheme: this.activeModel.id,
        isFast: this.mode,
        level: 2,
        referenceImgUrl:
          JSON.stringify(this.images) !== '{}'
            ? `${this.images.url}::${(this.weight * 2) / 100}`
            : undefined,
        extend: JSON.stringify(extend)
      }
      this.isWait = true
      const result = await createJob(params)
      if (!result) {
        this.isWait = false
      }
      // 开启轮询
      if (result.data) {
        this.taskId = result.data
        this.getDetail(result.data)
      }
    }
  },
  components: {
    layout,
    colorSelector,
    conditionSelector,
    basicSetting
  },
  mixins: [mixins]
}
</script>
    
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  border: 0;
  background: #f4f5f7;
}
::v-deep .el-textarea .el-input__count {
  color: #000;
  background: #f4f5f7;
}
::v-deep .el-slider__bar {
  background: #54c752;
}
::v-deep .el-slider__button {
  border-color: #54c752;
}
.conditionActive {
  background: #fff;
  border: 1px solid #54c752;
}
</style>