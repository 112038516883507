import service from "@/utils/require"

/** 获取全部模型 */
export const getModelTheme = (params) => {
    return service.get("/modelTheme/getPage", {
        params: {
            page: 1,
            limit: -1
      }
    })
}

/** 创建任务 */
export const createJob = (params) => {
  return service.post("/goapiJob/createJob", params)
}

/** 获取用户生成所需要的电力券及权益卡信息 */
export const getUserGeneratorCoupon = (params) => {
    return service.get('/goapiJob/getUserGeneratorCoupon', { params })
}

/** 获取任务生成详情 */
export const getJobDetail = (id) => { 
  return service.get(`/goapiJob/getDetails/${id}`)
}

/** 撤销 */
export const revocationCreator = (id) => {
    return service.delete(`/goapiJob/revocationCreator/${id}`)
}

/** 重新生成任务 */
export const generatorAgain = (id) => {
  return service.post(`/goapiJob/createJobAgain/${id}`)
}

/** 保存任务 */
export const saveJob = (id) => {
  return service.post(`/userImageStorage/saveImageStorage/${id}`)
}

/** 图片升档（微调） */
export const upscalImg = (id, upscaleIndex) => {
  return service.post(`/goapiJob/upscaleImg/${id}/${upscaleIndex}`)
}

/** 获取用户历史记录 */
export const getUserHistory = (param) => {
  return service.get('/goapiJobItem/getUserGeneratorHistory', {
    params:{
      ...param,
      taskStatus: "finish_success"   
    }
  })
}

/** 删除历史记录 */
export const deleteHistoryRecord = (id) => {
    return service.delete(`/goapiJobItem/deleteHistory/${id}`)
  }

/** 针对任务中的单个图片生成单个图片的详情任务 */
export const getSingleImg = (id, index) => { 
  return service.post(`/goapiJob/getSingleImg/${id}/${index}`)
}

/** 局部调整 */
export const inpaintImg = (params) => {
    return service.post(`/goapiJob/inpaintImg/${params.id}`, {
        ...params,
        id: undefined
    })
}

/** 获取条件 */

export const categoryList = (params) => {
    return service.get(`/mjInstructCategory/categoryList`, {
        params: {
          ...params
      }
    })
}

/** 随机生成词库 */

export const generateAutonomouslyMjContent = (params) => {
    return service.post(`/llm/generateAutonomouslyMjContent`, params)
}

/** 获取详情 */
export const getgeneratorDetail = (id) => {
  return service.get(`/llm/details/${id}`)
}

/** 获取用户可选择的配件类别列表 */
export const getUserAllPartClasses = () => {
  return service.get(`/partsClasses/userAllClasses`)
}

/** 获取用户可选择的配件分类列表 */
export const getUserAllPartCategory = (params) => {
  return service.get(`/partsClassify/userAllClassify`, {
    params: {
      ...params
    }
  })
}

/** 获取系统库数据 */
export const getSystemPartsPage = (params) => {
  return service.get(`/parts/systemPartsPage`, {
    params: {
      ...params
    }
  })
}

/** 获取我的库数据 */
export const getUserPartsPage = (params) => {
  return service.get(`/parts/userPartsPage`, {
    params: {
      ...params
    }
  })
}

/** 获取材质列表 */
export const getMaterialList = (params) => {
  return service.get(`/partsTexture/userAllTexture`, {
    params: {
      ...params
    }
  })
}

/** 保存配件 */
export const saveParts = (params) => {
  return service.post(`/goapiJob/partsChangeJob`, params)
}

/** 获取类别列表 */
export const getClassList = (params) => {
  return service.get(`/partsClasses/userAllClasses`, {
    params: {
      ...params
    }
  })
}

/** 获取供应商数据 */
export const getProvider = (params) => {
  return service.get(`/provider/providerListByName`, {
    params: {
      ...params
    }
  })
}

/** 上传配件 */
export const uploadParts = (params) => {
  return service.post(`/parts/userParts`, params)
}

/** 添加材质 */
export const addTexture = (params) => {
  return service.post(`/partsTexture/userTexture`, params)
}

/** 添加新类别 */
export const addClass = (params) => {
  return service.post(`/partsClasses/userClasses`, params)
}

/** 添加新分类 */
export const addCategory = (params) => {
  return service.post(`/partsClassify/userClassify`, params)
}

/** 添加供应商 */
export const addProvider = (params) => {
  return service.post(`/provider/userAddProvider`, params)
}

/** 重命名类别 */
export const renameClass = (params) => {
  return service.post(`/partsClasses/userClasses`, params)
}

/** 删除类别 */
export const deleteClassify = (id) => {
  return service.delete(`/partsClassify/userClassify/${id}`)
}

/** 以下是材料 */

/** 获取用户可选择的材料类别列表 */
export const getUserAllColorClasses = () => {
    return service.get(`/materialsClasses/userAllClasses`)
  }
  
  /** 获取用户可选择的材料分类列表 */
  export const getUserAllColorCategory = (params) => {
    return service.get(`/materialsClassify/userAllClassify`, {
      params: {
        ...params
      }
    })
  }
  
  /** 获取系统库数据 */
  export const getSystemColorsPage = (params) => {
    return service.get(`/materials/userMaterialsPage`, {
      params: {
        ...params
      }
    })
  }
  
  /** 获取我的库数据 */
  export const getUserColorsPage = (params) => {
    return service.get(`/materials/systemMaterialsPage`, {
      params: {
        ...params
      }
    })
  }
  
  /** 获取材质列表 */
  export const getMaterialListCL = (params) => {
    return service.get(`/materialsClassify/userAllClassify`, {
      params: {
        ...params
      }
    })
  }
  
  /** 保存配色 */
  export const saveColors = (params) => {
    return service.post(`/goapiJob/colorMaterialsChangeJob`, params)
  }
  
  /** 获取类别列表 */
  export const getClassListCL = (params) => {
    return service.get(`/partsClasses/userAllClasses`, {
      params: {
        ...params
      }
    })
  }
    
  /** 上传配件 */
  export const uploadColors = (params) => {
    return service.post(`/parts/userParts`, params)
  }
  
  /** 添加材质 */
  export const addTextureCL = (params) => {
    return service.post(`/materialsTexture/userTexture`, params)
  }
  
  /** 添加新类别 */
  export const addClassCL = (params) => {
    return service.post(`/materialsClasses/userClasses`, params)
  }
  
  /** 添加新分类 */
  export const addCategoryCL = (params) => {
    return service.post(`/materialsClassify/userClassify`, params)
  }
  
  
  /** 重命名类别 */
  export const renameClassCL = (params) => {
    return service.put(`/materialsClasses/userClasses`, params)
  }
  
  /** 删除类别 */
export const deleteClassifyCL  = (id) => {
    return service.delete(`/materialsClasses/userClasses/${id}`)
}
  
/** 发布 */
export const publishJob = (params) => {
    return service.post(`/creationArticle/clientSaveArticle`, params)
}

/** 根据id删除ai创作任务 */
export const deleteJob = (id) => {
    return service.delete(`/goapiJob/delJob/${id}`)
}

/** 根据id删除ai创作任务中指定子项  */
export const deleteJobItem = (id) => {
    return service.delete(`/goapiJob/delJobItem/${id}`)
}