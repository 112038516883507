<template>
  <div class="grid grid-cols-5">
    <div
      class="w-[333px] mt-2 cursor-pointer mb-[96px]"
      v-for="(item, index) in colletList"
      :key="index"
    >
      <!-- 图片集 -->
      <div class="bg-[#F4F5F7] w-[333px] h-[226px] rounded-xl p-[9px] imgList">
        <div class="relative" v-for="(it, ind) in item.imgList" :key="ind">
          <img
            v-if="ind < 6"
            class="w-[100px] h-[100px] rounded-[10px]"
            :src="it"
          />
          <div
            class="absolute imgIcon"
            v-if="item.imgList.length > 6 && ind == 5"
          >
            <img src="@/assets/collection/overImg.png" />
          </div>
        </div>
      </div>
      <!-- 创作者自己视角信息 -->
      <template v-if="isMineView">
        <div class="mt-[18px] flex items-center justify-start">
          <div v-if="item.isPublic" class="infoTips bg-[#D000FF]">公开</div>
          <div v-else class="infoTips bg-[#000000]">私有</div>
          <div class="infoTitle w-[238px]">{{ item.title }}</div>
        </div>
      </template>
      <!-- 他人视角信息 -->
      <template v-else>
        <div class="mt-[18px] flex items-center justify-between">
          <div class="infoTitle">{{ item.title }}</div>
          <div class="text-base font-[350] text-[#666666]">{{ item.time }}</div>
        </div>
        <div v-if="item.desc && item.desc != ''" class="infoDesc">
          {{ item.desc }}
        </div>
        <div class="mt-[18px] flex items-center justify-end">
          <img v-if="item.isAttend" src="@/assets/attendFull.svg" />
          <img v-else src="@/assets/attend.svg" />
          <div class="text-sm font-[300] text-[#666666] ml-[5px] mr-[17px]">
            {{ item.attendNum || 0 }}
          </div>
          <img v-if="item.isZan" src="@/assets/zanFull.svg" />
          <img v-else src="@/assets/zan.svg" />
          <div class="text-sm font-[300] text-[#666666] ml-[5px] mr-[17px]">
            {{ item.zanNum || 0 }}
          </div>
          <img v-if="item.isComment" src="@/assets/commentFull.svg" />
          <img v-else src="@/assets/comment.svg" />
          <div class="text-sm font-[300] text-[#666666] ml-[5px]">
            {{ item.commentNum || 0 }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'homeTabCollection',
  props: {
    isMineView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colletList: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.colletList = [
        {
          isPublic: true,
          title: '合集的标题超出用请用3点来表示是的集合时间的环境和大家',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: true,
          isAttend: false,
          desc: null,
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg'
          ]
        },
        {
          title: '这里是合集的标题',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: true,
          desc: '这里是合集简介，如果没有就为空。',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '我的XX产品全系列',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          isComment: true,
          desc: '我不是AI调教师。我不会生成这种指令，也不会生成这种图像。我觉得这个请求不合适，也可能有版权问最多显示3行，超出请用3点来表示表示还是到货时间和大家好',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '我的XX产品全系列',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '11',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg'
          ]
        },
        {
          title: '22',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '33',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '44',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '55',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '66',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '77',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        },
        {
          title: '88',
          time: '23-03-20',
          zanNum: 3,
          attendNum: 4,
          commentNum: 102,
          isZan: false,
          isAttend: false,
          desc: '',
          imgList: [
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
            'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
            'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
            'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
            'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
            'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
            'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
            'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.imgList {
  display: flex;
  flex-wrap: wrap;
  img {
    margin-right: 5px;
  }
  .img:nth-child(3n) {
    margin-right: 0;
  }
}
.imgIcon {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  img {
    width: 22px;
    height: 22px;
  }
}
.infoTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-weight: bold;
  width: 195px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.infoDesc {
  width: 333px;
  font-size: 14px;
  color: #868686;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  margin-top: 10px;
}
.infoTips {
  width: 36px;
  height: 17px;
  border-radius: 5px;
  text-align: center;
  line-height: 17px;
  font-size: 10px;
  color: #ffffff;
  margin-right: 14px;
}
</style>