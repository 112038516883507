<template>
  <div class="flex items-center justify-between py-2 px-8 border-b">
    <div>
      <div class="flex items-center">
        <div @click="$router.push('/')">
          <img src="@/assets/shejilogo.svg" alt="" />
        </div>
        <div class="ml-10 flex min-w-80">
          <template v-if="showMenu">
            <div
              class="ml-6 cursor-pointer"
              v-for="(item, key) in headerItem"
              :key="key"
            >
              <router-link
                :to="item.link"
                class="text-lg"
                active-class="active_router"
                v-if="!item.disabled"
              >
                {{ item.title }}
              </router-link>
              <div
                class="text-lg"
                v-else
                @click="$message.warning('该板块暂未开放')"
              >
                {{ item.title }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-around">
      <img src="@/assets/register.svg" alt="" class="cursor-pointer" />
      <ElInput
        placeholder="请输入搜索关键词"
        prefix-icon="el-icon-search"
        style="width: 192px"
        class="ml-4"
      ></ElInput>
      <div
        class="w-28 p-1 rounded-[50px] border border-[#DBDBDB] flex items-center justify-around cursor-pointer ml-4"
      >
        <img src="@/assets/change.svg" />
        切换领域
      </div>
      <img src="@/assets/notification.svg" class="cursor-pointer ml-4" />
      <RouterLink to="/login" v-if="JSON.stringify(userInfo) === '{}'">
        <div
          class="w-28 p-1 rounded-[50px] border border-[#666666] flex items-center justify-around cursor-pointer ml-4"
        >
          登录/注册
        </div>
      </RouterLink>
      <div v-else class="flex items-center">
        <img
          :src="require(`@/assets/user/${userInfo.level}.svg`)"
          alt=""
          class="w-24 h-12 object-fill mr-6 ml-4"
          v-if="userInfo.isVip"
        />
        <el-popover placement="bottom-start" width="450" trigger="click">
          <div>
            <div class="flex items-center px-2">
              <div class="text-lg font-extrabold">{{ userInfo.nickName }}</div>
              <img
                :src="require(`@/assets/user/${userInfo.level}.svg`)"
                alt=""
                class="w-28 h-16 object-fill ml-6"
                v-if="userInfo.isVip"
              />
            </div>
            <!-- <div>
              <div>关注{{}}</div>
              <div>粉丝{{}}</div>
              <div>发布{{}}</div>
            </div> -->
            <div class="grid grid-cols-5 mt-4 gap-2">
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/home')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/home.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">我的主页</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/memberCard')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/vip.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">我的会员</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/electron')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/electron.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">电力券</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/albumCenter')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/creator.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">创作者中心</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/likeAndCollect')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/good.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">赞和收藏</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/myService')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/service.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">服务</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/myOrder')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/order.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">订单</span>
              </div>
              <div
                class="cursor-pointer flex flex-col justify-center items-center"
                @click="$router.push('/my/myIncome')"
              >
                <img
                  :src="
                    require(`@/assets/user/${
                      [1, 2, 3].includes(userInfo.level) ? userInfo.level : 0
                    }/income.svg`)
                  "
                  alt=""
                />
                <span class="mt-1">收益</span>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="flex p-2 pt-0 cursor-pointer" @click="logout">
              <img src="@/assets/logout.svg" alt="" />
              <span class="ml-2">退出登录</span>
            </div>
          </div>
          <img
            :src="userInfo.avatar"
            alt=""
            class="w-10 h-10 rounded-full cursor-pointer"
            slot="reference"
          />
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/user'

export default {
  name: 'shejiHeader',
  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      headerItem: [
        {
          title: '首页',
          link: '/home'
        },
        {
          title: 'AI创作',
          link: '/workbench'
        },
        {
          title: '关注',
          link: '/concern'
        },
        {
          title: '发现',
          link: '/discover'
        },
        {
          title: '资讯',
          link: '/information'
        },
        {
          title: '服务商',
          link: '/service'
        },
        {
          title: '我的图库',
          link: '/mylibrary'
        }
      ],
      userInfo: {}
    }
  },
  methods: {
    logout() {
      this.$confirm('确定退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('authorization')
        localStorage.removeItem('userInfo')
        this.$router.push('/login')
      })
    }
  },
  mounted() {
    if (localStorage.getItem('authorization')) {
      this.userInfo = getUserInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.active_router {
  @apply font-black relative;
  &::after {
    @apply block absolute right-0 h-1;
    content: '';
    width: 14px;
    border-radius: 2.5px;
    background: #54c752;
  }
}
::v-deep .el-input__inner {
  @apply rounded-3xl;
}
</style>
